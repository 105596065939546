import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"

import Typography from "/src/components/ui/base/typography"
import { graphql, useStaticQuery } from "gatsby"
import Header from "../../components/ui/base/layout/header"
import Breadcrumbs from "../../components/ui/base/Breadcrumbs"
import classnames from "classnames"
import * as styles from "./index.module.scss"
import Section from "../../components/ui/base/Section"
import DestinationCard from "../../components/ui/base/DestinationCard"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import { useTranslation } from "react-i18next"
import {
  BASE_URL,
  DESTINATIONS_URL,
  PHOTO_TOURS_URL,
} from "../../util/constants"

const DestinationPage = () => {
  const data = useStaticQuery(query)
  const { i18n: l } = useTranslation()
  const destinations = data?.allStrapiState.edges
  const seo = data?.strapiTourDestinationPage.seo[0]
  return (
    <>
      <GatsbySeo
        htmlAttributes={{ lang: l.language }}
        title={seo?.metaTitle}
        language={l.language}
        description={seo?.metaDescription}
        canonical={`${BASE_URL[l.language]}/${PHOTO_TOURS_URL[l.language]}/${
          DESTINATIONS_URL[l.language]
        }/`}
      />
      <Header size={"compact"} videoURL={"viaggi-fotografici"} theme="dark">
        <Container fluid="xl">
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="intro_title text-center">
                <Typography className="animated fadeInDown" variant="heading1">
                  Incredibili destinazioni per i tuoi viaggi fotografici
                </Typography>
                <Breadcrumbs
                  elements={[
                    {
                      name: "WeShoot",
                      path: "/",
                    },
                    {
                      name: "Viaggi Fotografici",
                      path: "/viaggi-fotografici/",
                    },
                    {
                      name: "Destinazioni",
                      path: "/viaggi-fotografici/destinazioni/",
                    },
                  ]}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Header>

      <Section variant={"white"}>
        <div className={classnames([styles.statesContainer])}>
          {destinations?.map(d => {
            if (d.node.locale === l.language) {
              return <DestinationCard destination={d.node} key={d.node.slug} />
            } else {
              return <></>
            }
          })}
        </div>
      </Section>
    </>
  )
}

const query = graphql`
  {
    strapiTourDestinationPage {
      seo {
        metaDescription
        metaTitle
      }
      description
      title
    }
    allStrapiState {
      edges {
        node {
          image {
            localFile {
              ...ImageFragment
            }
            alternativeText
            caption
          }
          slug
          locale
          name
        }
      }
    }
  }
`

export default DestinationPage
