import classnames from "classnames"
import React from "react"
import Link from "/src/components/ui/base/Link"
import * as styles from "./index.module.scss"
import Typography from "/src/components/ui/base/typography"
import { GatsbyImage } from "gatsby-plugin-image"


const DestinationCard = ({ destination }) => {

    const destinationLink = '/viaggi-fotografici/destinazioni/' + destination.slug + '/';

    return (
      <Link className={classnames([styles.container])} to={destinationLink}>
        <GatsbyImage  className={classnames([styles.gatsbyImage])} image={destination.image.localFile.childImageSharp.gatsbyImageData} alt={`Viaggi Fotografici in ${destination.name}`}/>
        <div className={classnames([styles.wrapper])}>
          <Typography
            variant="heading2">
            {destination.name}
          </Typography>

        </div>
      </Link>
    )
}


export default DestinationCard
